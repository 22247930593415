import { t } from "i18next";
import { useCallback } from "react";
import { CreateEntity } from "..";
import { Entity } from "../../../models/enums";
import { BuildingIcon, FactoryIcon, TubeIcon } from "../../../sg-react/icons";
import { StepComponentProps } from "../../../sg-react/ui/Stepper";

const SelectTypeStep = ({ onChange, value }: StepComponentProps<CreateEntity>) => {

    const handleChange = useCallback((t: Entity) => {
        if (t !== value.type) {
            onChange({ type: t });
        }
    }, [value, onChange]);

    return (
        <div id="data-management-select-entity">
            <div className={value.type === Entity.Company ? 'active' : ''} onClick={() => handleChange(Entity.Company)}>
                <BuildingIcon />
                <span>{t('companies:entity')}</span>
            </div>
            <div className={value.type === Entity.Branch ? 'active' : ''} onClick={() => handleChange(Entity.Branch)}>
                <FactoryIcon />
                <span>{t('branches:entity')}</span>
            </div>
            <div className={value.type === Entity.Specification ? 'active' : ''} onClick={() => handleChange(Entity.Specification)}>
                <TubeIcon />
                <span>{t('specifications:entity')}</span>
            </div>
        </div>
    )
}

export default SelectTypeStep;