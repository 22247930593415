import { useTranslation } from "react-i18next";
import BranchForm from "../../components/BranchForm";
import { BRANCH_VALIDATION, Branch } from "../../models/branch";
import { DataList } from "../../sg-react/data";
import { DataListSchema } from "../../sg-react/data/DataList";

const LIST_SCHEMA: DataListSchema<Branch> = {
    id: {
        label: 'ID',
        width: '5%',
        options: {
            order: true,
        }
    },
    name: {
        label: 'Name',
        display: 'textinput',
        width: '20%',
        options: {
            order: true,
            search: true,
            edit: true,
        }
    },
    streetNumber: {
        label: 'Number',
        width: '5%',
        options: {
            order: true,
        }
    },
    route: {
        label: 'Address',
        width: '15%',
        options: {
            order: true,
        }
    },
    city: {
        label: 'City',
        display: 'textinput',
        width: '10%',
        options: {
            order: true,
            ilike: true,
            null: true,
        }
    },
    region: {
        label: 'Region',
        display: 'textinput',
        width: '10%',
        options: {
            order: true,
            ilike: true,
            null: true,
        }
    },
    country: {
        label: 'Country',
        display: 'textinput',
        width: '10%',
        options: {
            order: true,
            ilike: true,
            null: true,
        }
    },
    lng: {
        label: 'Geolocalised',
        display: (e) => e.lat && e.lng ? 'Yes' : 'No',
        width: '10%',
        options: {
            order: true,
            null: true,
        }
    }
}

const BranchesDataList = () => {
    const { t } = useTranslation();

    return (
        <DataList
            title={t('branches:title')}
            schema={LIST_SCHEMA}
            primaryKey="id"
            validation={BRANCH_VALIDATION}
            actions={{ merge: true, view: true }}
            endpoint="/branches"
            updateForm={BranchForm}
            createForm={BranchForm}
        />
    );
}

export default BranchesDataList;