import { useEffect, useMemo } from "react";
import { CreateEntity } from "..";
import BranchForm from "../../../components/BranchForm";
import CompanyForm from "../../../components/CompanyForm";
import SpecificationForm from "../../../components/SpecificationForm";
import { Entity } from "../../../models/enums";
import { useForm } from "../../../sg-react/hooks";
import { StepComponentProps } from "../../../sg-react/ui/Stepper";
import { Card } from "../../../sg-react/ui";

const EditStep = ({ onChange, value, errors }: StepComponentProps<CreateEntity>) => {
    const { entity: formEntity, setErrors, ...formProps } = useForm<any>({ ...value.entity });

    const FormComponent = useMemo(() => {
        if (value.type === Entity.Branch) {
            return BranchForm;
        } else if (value.type === Entity.Specification) {
            return SpecificationForm;
        } else {
            return CompanyForm;
        }
    }, [value]);

    useEffect(() => {
        onChange({ ...value, entityEdited: formEntity });
    }, [formEntity]);

    useEffect(() => {
        if (errors) {
            setErrors(errors);
        }
    }, [errors]);

    return <Card><FormComponent entity={formEntity} {...formProps} revision /></Card>;
}

export default EditStep;