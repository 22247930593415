// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
#specification-form {
  width: 800px;
  display: flex;
  flex-direction: column;
  color: rgb(254, 255, 254);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/styles/variables.scss","webpack://./src/components/SpecificationForm/index.scss"],"names":[],"mappings":"AAAA,cAAA;ACEA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,yBDLI;ACKR","sourcesContent":["/* Variables */\n$white: rgb(254, 255, 254);\n$black: rgb(1, 5, 8);\n\n$pantone-p-179-15-C: #353535;\n$pantone-p-123-15-U: #3b6d70;\n$pantone-1375-C: #fca211;\n$pantone-p-179-2-C: #d9d9d9;\n$pantone-p-179-1-U: #ffffff;\n$pantone-2168-C: #284b63;\n\n$primary: $pantone-p-123-15-U;\n$secondary: $pantone-p-179-15-C;\n$accent: $pantone-1375-C;\n$grey-lightest: rgb(247, 247, 247);\n$grey-lighter: rgb(242, 243, 247);\n$grey-light: rgb(223, 225, 231);\n$grey-medium: rgb(152, 155, 162);\n$grey-dark: rgb(94, 94, 94);\n$grey-darker: rgb(60, 64, 74);\n$grey-darkest: rgb(38, 41, 47);\n\n$error: rgb(208, 46, 46);\n$warning: rgb(219, 136, 64);\n$success: rgb(40, 175, 60);\n\n$palette-color1: #9e0142;\n$palette-color2: #d53e4f;\n$palette-color3: #f46d43;\n$palette-color4: #fdae61;\n$palette-color5: #fee08b;\n$palette-color6: #e6f598;\n$palette-color7: #abdda4;\n$palette-color8: #66c2a5;\n$palette-color9: #3288bd;\n$palette-color10: #5e4fa2;\n\n@mixin scrollbar {\n    scrollbar-width: thin;\n    scrollbar-color: transparent $primary;\n\n    &::-webkit-scrollbar {\n        width: 6px;\n        height: 6px;\n    }\n    &::-webkit-scrollbar-track {\n        background: transparent;\n    }\n    &::-webkit-scrollbar-thumb {\n        background-color: $primary;\n        border-radius: 4px;\n    }\n}\n","@import \"src/sg-react/styles/variables.scss\";\n\n#specification-form {\n    width: 800px;\n    display: flex;\n    flex-direction: column;\n    color: $white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
