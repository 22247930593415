import { DataListSchema } from "../sg-react/data/DataList";
import { EntityValidation, ValidationRule } from "./../sg-react/hooks/useForm";
import { Branch } from "./branch";

export type Company = {
    id: number;
    name: string;
    logo?: string;
    description?: string;
    streetNumber?: string;
    route?: string;
    city: string;
    region?: string;
    country: string;
    postalCode?: string;
    address?: string;
    localPhone?: string;
    internationalPhone?: string;
    website?: string;
    lat?: number;
    lng?: number;
    branches?: Branch[];
};

export const COMPANY_VALIDATION: EntityValidation<Company> = {
    name: [{ rule: ValidationRule.Required }],
    address: [{ rule: ValidationRule.Required }],
    lat: [{ rule: ValidationRule.Required }, { rule: ValidationRule.Number }],
    lng: [{ rule: ValidationRule.Required }, { rule: ValidationRule.Number }],
};

export const COMPANY_LIST_SCHEMA: DataListSchema<Company> = {
    id: {
        label: "ID",
        width: "5%",
        options: {
            order: true,
        },
    },
    name: {
        label: "Name",
        display: "textinput",
        width: "20%",
        options: {
            order: true,
            search: true,
            edit: true,
        },
    },
    streetNumber: {
        label: "Number",
        width: "5%",
        options: {
            order: true,
        },
    },
    route: {
        label: "Address",
        width: "15%",
        options: {
            order: true,
        },
    },
    city: {
        label: "City",
        display: "textinput",
        width: "10%",
        options: {
            order: true,
            ilike: true,
            null: true,
        },
    },
    region: {
        label: "Region",
        display: "textinput",
        width: "10%",
        options: {
            order: true,
            ilike: true,
            null: true,
        },
    },
    country: {
        label: "Country",
        display: "textinput",
        width: "10%",
        options: {
            order: true,
            ilike: true,
            null: true,
        },
    },
    lng: {
        label: "Geolocalised",
        display: (e) => (e.lat && e.lng ? "Yes" : "No"),
        width: "10%",
        options: {
            order: true,
            null: true,
        },
    },
};
