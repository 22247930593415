import { FeatureCollection } from "geojson";
import { t } from "i18next";
import { CircleLayer, Map as MapGl, MapMouseEvent } from 'mapbox-gl';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Layer, Marker, Popup, Source } from "react-map-gl";
import { LngLatBounds } from "react-map-gl/dist/esm/types";
import useSuperCluster from "use-supercluster";
import BranchCard from "../../components/BranchCard";
import BranchList from "../../components/BranchList";
import NadcapPie from "../../components/NadcapPie";
import { BranchNadcap } from "../../models/branch";
import { Nadcap } from "../../models/enums";
import { Map, Search } from '../../sg-react/data';
import { MapRefProps } from "../../sg-react/data/Map";
import { useRequest } from "../../sg-react/hooks";
import { BulletIcon, ConnectPolygonIcon, FiltersHorizontalIcon } from "../../sg-react/icons";
import MapFilters from "./MapFilters";
import MapResults from "./MapResults";
import './index.scss';
import { Dropdown } from "../../sg-react/ui";
import { CheckboxList } from "../../sg-react/form";
import { areAllInside, areAllOutside } from "../../sg-react/utils/geo";

export interface FiltersForContext {
    geonames?: { key: string | number, radius?: number }[];
    nadcap?: Nadcap[];
    specifications?: { key?: number | string }[];
    name: string;
    color?: string;
}

export interface Filters {
    mode: 'plan' | 'explore';
    filters: FiltersForContext[];
    moveToGeonames?: boolean;
}

export interface MapViewResult {
    color?: string;
    name: string;
    branches: BranchNadcap[]
};

const clusterOptions = {
    radius: 40,
    maxZoom: 15,
    map: (props: any) => ({
        branches: [props.branch]
    }),
    reduce: (acc: any, props: any) => {
        acc.branches = [...acc.branches, ...props.branches];
        return acc;
    }
};


const MainMap = () => {
    const [results, setResults] = useState<MapViewResult[]>([]);
    const [isInit, setInit] = useState<boolean>(false);
    const request = useRequest();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [getAfterMove, setGetAfterMove] = useState<boolean>(false);
    const [selectedBranch, setSelectedBranch] = useState<BranchNadcap | null>(null);
    const [popup, setPopup] = useState<{ lng: number, lat: number, branches?: BranchNadcap[] } | null>(null);
    const [isFiltersPanelExpanded, setFiltersPanelExpanded] = useState<boolean>(true);
    const [isResultsPanelExpanded, setResultsPanelExpanded] = useState<boolean>(false);
    const [visibleLayers, setVisibleLayers] = useState<string[]>([]);
    const [filters, setFilters] = useState<Filters | null>(null);
    const [bounds, setBounds] = useState<LngLatBounds | null>(null);
    const mapRef = useRef<MapRefProps>(null);

    const { clusters } = useSuperCluster({
        points: (results.length === 1 ? results[0].branches : []).map(branch => ({
            type: "Feature",
            properties: { cluster: false, point_count: 1, branch },
            geometry: { "type": "Point", "coordinates": [branch.lng, branch.lat] }
        })),
        bounds: bounds ? [
            bounds.getSouthWest().lng,
            bounds.getSouthWest().lat,
            bounds.getNorthEast().lng,
            bounds.getNorthEast().lat
        ] : undefined,
        zoom: mapRef.current?.getZoom() ?? 12,
        options: clusterOptions,
    });

    const get = useCallback(async (filters: Filters | null, r?: MapGl) => {
        if (isLoading || !filters?.filters?.length) return;

        setLoading(true);

        const params: { [k: string]: any } = { filters: filters.filters };

        if (filters.mode === 'explore') {
            const bounds = mapRef.current?.getBounds() ?? r?.getBounds();

            if (!bounds) return;

            params.coordinates = { lng: bounds.getCenter().lng, lat: bounds.getCenter().lat };
            params.bounds = {
                nw: {
                    lng: Math.min(bounds.getNorthWest().lng, bounds.getSouthEast().lng),
                    lat: Math.max(bounds.getNorthWest().lat, bounds.getSouthEast().lat)
                },
                se: {
                    lng: Math.max(bounds.getNorthWest().lng, bounds.getSouthEast().lng),
                    lat: Math.min(bounds.getNorthWest().lat, bounds.getSouthEast().lat)
                },
            }
        }

        request.get<MapViewResult[]>('/branches/view', { params, errorMessage: 'error.server_error', i18n: true, loader: true })
            .then((results) => {
                setResults(results);
                if (filters.mode === 'plan' || (mapRef.current && areAllOutside(results[0].branches, mapRef.current.getBounds()!))) {
                    mapRef.current?.fitToEntities(results.map(r => r.branches).flat());
                }
                setVisibleLayers(results.map(r => r.name));
            })
            .catch(() => null)
            .finally(() => {
                setLoading(false);
                setPopup(null);
                setGetAfterMove(false);
            });

    }, [isLoading, getAfterMove]);

    const handleSelectBranch = useCallback((branch: BranchNadcap, get?: boolean) => {
        setSelectedBranch(branch);
        if (mapRef.current) {
            const bounds = mapRef.current.getBounds();

            if (bounds && !areAllInside([branch], bounds)) {
                mapRef.current.goTo(branch.lng, branch.lat, mapRef.current.getZoom());
            }
            if (filters?.mode === 'explore' && get) setGetAfterMove(true);
        }
    }, [filters]);

    const handleMouseMove = useCallback((e: MapMouseEvent) => {
        if ((e as any).features?.length) {
            const branches: BranchNadcap[] = (e as any).features?.map((f: any) => {
                try {
                    const branch = JSON.parse(f.properties.branch ?? '{}');
                    return { ...branch, stepColor: f.properties.stepColor, stepName: f.properties.stepName };
                } catch {
                    return null;
                }
            }).filter((b: any) => !!b);

            if (branches.length) {
                setPopup((popup) => {
                    if (branches.some(b1 => !popup?.branches?.some(b2 => b2.id === b1.id))) {
                        return ({
                            lng: branches[0].lng,
                            lat: branches[0].lat,
                            branches
                        })
                    }
                    return popup;
                });
            }
        }
    }, []);

    const ids = useMemo(() => results.map(r => r.branches.map(b => b.id)).flat(), [results]);

    const layers = useMemo(() => results.length > 1 && results?.map((s, i) => {
        const geojson: FeatureCollection = {
            type: 'FeatureCollection' as 'FeatureCollection',
            features: s.branches.map(b => ({
                type: 'Feature',
                geometry: { type: 'Point', coordinates: [b.lng, b.lat] },
                properties: {
                    branch: b,
                    stepName: s.name,
                    stepColor: s.color
                },
            }))
        };

        const layerStyle: CircleLayer = {
            id: 'point',
            type: 'circle',
            paint: {
                'circle-radius': 10,
                'circle-color': s.color ?? '#ffffff',
                'circle-opacity': 0.7,
                'circle-stroke-color': '#303030',
                'circle-stroke-opacity': 1,
                'circle-stroke-width': 1,
            }
        };

        return (
            <Source key={s.name} id={s.name} type="geojson" data={geojson}>
                <Layer {...layerStyle} id={s.name} />
            </Source>
        );
    }), [results]);

    const markers: ReactElement[] = useMemo<ReactElement[]>(() =>
        clusters.map((c => c.properties.cluster
            ? <Marker
                key={`cluster-${c.id}`}
                longitude={c.geometry.coordinates[0]}
                latitude={c.geometry.coordinates[1]}
                anchor="center"
                onClick={() => { mapRef.current?.fitToEntities((c.properties as any).branches as BranchNadcap[], { animate: true }); }}
            >
                <div
                    className="marker-cluster"
                    style={!!results[0]?.branches?.length ? { width: `${30 + (c.properties.point_count / results[0]?.branches?.length) * 50}px`, height: `${30 + (c.properties.point_count / results[0]?.branches?.length) * 50}px` } : undefined}
                    onMouseEnter={() => setPopup({
                        lng: c.geometry.coordinates[0],
                        lat: c.geometry.coordinates[1],
                        branches: (c.properties as any).branches as BranchNadcap[]
                    })}
                >
                    {c.properties.point_count}
                </div>
            </Marker >
            : <Marker
                key={`marker-${(c.properties as any).branch.id}`}
                longitude={c.geometry.coordinates[0]}
                latitude={c.geometry.coordinates[1]}
                onClick={() => setSelectedBranch((c.properties as any).branch as BranchNadcap)}
                anchor="center"
            >
                <div
                    className="marker-branch"
                    onMouseEnter={() => setPopup({
                        lng: c.geometry.coordinates[0],
                        lat: c.geometry.coordinates[1],
                        branches: [(c.properties as any).branch as BranchNadcap]
                    })}
                >
                    <NadcapPie branch={(c.properties as any).branch as BranchNadcap} />
                </div>
            </Marker>)),

        [clusters]);

    useEffect(() => {
        get(filters);
        setSelectedBranch(null);
    }, [filters]);

    useEffect(() => {
        if (getAfterMove) {
            get(filters);
        }
    }, [getAfterMove]);

    useEffect(() => {
        if (mapRef.current && filters?.mode === 'plan') {
            const map = mapRef.current.getMap();

            if (map) {
                for (const f of filters?.filters ?? []) {
                    map.setLayoutProperty(f.name, 'visibility', visibleLayers.includes(f.name) ? 'visible' : 'none');
                }
            }
        }
    }, [visibleLayers]);

    return (
        <div id="map-context">
            {!!isInit && (
                <div id="map-context-panel">
                    <MapFilters
                        onSubmit={setFilters}
                        expanded={isFiltersPanelExpanded}
                    />
                    <MapResults
                        results={results}
                        expanded={isResultsPanelExpanded}
                        onBranchClick={(b) => handleSelectBranch(b)}
                    />
                </div>
            )}
            <div id="map-context-map">
                <div id="map-context-panel-float">
                    {selectedBranch && <BranchCard branch={selectedBranch} filtered onClose={() => setSelectedBranch(null)} />}
                </div>
                <div id="map-context-actions">
                    <Search<BranchNadcap>
                        placeholder={t('branches:search')}
                        endpoint={`/branches/map-search`}
                        method="post"
                        onClick={(e) => e.entity ? handleSelectBranch(e.entity, true) : null}
                        className="main-map-goto"
                        additionalFilters={results.length > 1 ? { ids } : { filters: filters?.filters ?? [] }}
                    />
                    <div id="map-context-pills">
                        <div
                            className={`map-context-pill ${isFiltersPanelExpanded ? 'selected' : ''}`}
                            onClick={() => setFiltersPanelExpanded(!isFiltersPanelExpanded)}
                        >
                            <FiltersHorizontalIcon />
                            <span>{t('filters:filters')}</span>
                        </div>
                        <div
                            className={`map-context-pill ${isResultsPanelExpanded ? 'selected' : ''}`}
                            onClick={() => setResultsPanelExpanded(!isResultsPanelExpanded)}
                        >
                            <BulletIcon />
                            <span>{t('filters:results')}</span>
                        </div>
                        {results.length > 1 && (
                            <div
                                id="map-context-layers"
                                className={`map-context-pill ${visibleLayers?.length !== results.length ? 'selected' : ''}`}
                            >
                                <ConnectPolygonIcon />
                                <span>{t('actions:steps')}</span>
                                <div id="map-context-layers-dropdown">
                                    <CheckboxList<string[]> id="map-context-layers" value={visibleLayers} items={filters?.filters.map(f => f.name) ?? []} onChange={(v) => setVisibleLayers(v ?? [])} multiple />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Map
                    ref={mapRef}
                    markers={markers}
                    onMouseMove={results.length > 1 ? handleMouseMove : undefined}
                    interactiveLayerIds={results?.map((s) => s.name)}
                    onLoad={() => setInit(true)}
                    onMoveEnd={(e) => setBounds(e.target.getBounds())}
                >
                    {layers}
                    {!!selectedBranch && (
                        <Marker
                            longitude={selectedBranch.lng}
                            latitude={selectedBranch.lat}
                            anchor="center"
                            style={{ zIndex: 30 }}
                        />
                    )}
                    {!!popup && !!popup.branches?.length && (
                        <Popup
                            longitude={popup.lng}
                            latitude={popup.lat}
                            anchor="left"
                            offset={10}
                            className="main-map-popup"
                            onClose={() => setPopup(null)}
                        >
                            <BranchList small branches={popup.branches} onBranchClick={setSelectedBranch} />
                        </Popup>
                    )}
                </Map>
            </div>
        </div >
    );
};

export default MainMap;