import { useTranslation } from "react-i18next";
import { MapViewResult } from ".";
import BranchList from "../../components/BranchList";
import { BulletIcon, DownIcon, UpIcon } from "../../sg-react/icons";
import { BranchNadcap } from "../../models/branch";
import { useMemo, useState } from "react";
import { Select } from "../../sg-react/form";

interface MapResultsProps {
    expanded: boolean;
    results: MapViewResult[];
    onBranchClick: (b: BranchNadcap) => void;
}

const MapResults = ({ results, expanded, onBranchClick }: MapResultsProps) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<number>(-1);

    const steps = useMemo(() => [
        ...results.map((r, i) => ({ key: String(i), label: r.name })),
        { key: '-1', label: t('actions:all_steps') }
    ], [results]);

    const branches = useMemo(() => (step === -1
        ? results.map(r => r.branches).flat()
        : results[step].branches), [results, step]);

    return (
        <div id="map-results-panel" className={`map-context-panel-item ${expanded ? 'expanded' : ''}`}>
            <div className="map-context-panel-item-header">
                <BulletIcon />
                <span>{t('filters:results')}</span>
                <div id="map-results-actions">
                    {results.length > 1 && (
                        <div id="map-results-filter-steps">
                            <Select
                                small
                                inline
                                id="steps"
                                value={String(step)}
                                items={steps}
                                onChange={(v) => setStep(Number(v))}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="map-context-panel-item-content">
                <BranchList branches={branches} onBranchClick={onBranchClick} />
            </div>
        </div>
    )
}

export default MapResults;