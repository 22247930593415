import { SimpleListSchema } from "../sg-react/data/SimpleList";
import { EntityValidation, ValidationRule } from "../sg-react/hooks/useForm";
import { CompilationLink } from "./compilation";
import { Nadcap, Privacy } from "./enums";

export interface Specification {
    id: number;
    specification: string;
    nadcapCommodity?: Nadcap[];
    designation?: string;
    designationKeywords?: string;
    designationPrivate?: string;
    privacy?: Privacy;
    compiledEntities?: SpecificationCompiled[];
}

export interface SpecificationCompiled extends CompilationLink {
    id: number;
    specification: string;
    nadcap_commodity?: Nadcap[];
    designation?: string;
}

export const SPECIFICATION_VALIDATION: EntityValidation<Specification> = {
    specification: [{ rule: ValidationRule.Required }],
    designation: [{ rule: ValidationRule.Required }],
};

export const SPECIFICATION_SIMPLE_LIST_SCHEMA: SimpleListSchema<Specification> =
    {
        specification: {
            label: "Specification",
        },
        nadcapCommodity: {
            label: "Nadcap commodity",
        },
        designation: {
            label: "Designation",
        },
    };
