import { useTranslation } from 'react-i18next';
import { Specification } from '../../models/specification';
import { NADCAP_COLORS } from '../../shared/constants';
import './index.scss'
import { ViewIcon } from '../../sg-react/icons';
import { Privacy } from '../../models/enums';
import { useEffect, useState } from 'react';
import { useRequest } from '../../sg-react/hooks';

interface SpecificationListProps {
    specifications?: Specification[];
    endpoint?: string;
}

const SpecificationList = ({ specifications: specificationsFromProps, endpoint }: SpecificationListProps) => {
    const { t } = useTranslation();
    const [specifications, setSpecifications] = useState<Specification[]>([]);
    const request = useRequest();

    useEffect(() => {
        if (endpoint) {
            request.get<Specification[]>(endpoint)
                .then(setSpecifications)
                .catch(() => null);
        } else if (specificationsFromProps) {
            setSpecifications(specificationsFromProps);
        }
    }, [endpoint, specificationsFromProps]);

    return (
        <div className="specification-list">
            <div className="specification-list-filters">

            </div>
            {specifications?.map(s => (
                <div key={String(s.id)} className="specification-list-item">
                    <div className="specification-list-header">
                        <div className="specification-list-name">
                            {s.specification}
                            {s.privacy === Privacy.Protected && <ViewIcon className="specification-list-protected" />}
                            {s.privacy === Privacy.Private && <ViewIcon className="specification-list-private" />}
                        </div>
                        {s.nadcapCommodity?.map(n => (
                            <div
                                key={n}
                                className="specification-list-nadcap"
                                style={{ backgroundColor: NADCAP_COLORS[n] }}
                            >
                                {t('nadcap:' + n.toLowerCase())}
                            </div>
                        ))}
                    </div>
                    {!!s.designation && (
                        <div className="specification-list-designation">
                            {s.designation}
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default SpecificationList;