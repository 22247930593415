import { SimpleListSchema } from "../sg-react/data/SimpleList";
import { dateToLocaleString } from "../sg-react/utils/date";

export type UserPreference = {
    id: number;
    name: string;
    type: UserPreferenceType;
    data: { [k: string]: any };
    createdAt: Date;
    updatedAt?: Date;
};

export enum UserPreferenceType {
    MapFilters = "MAP_FILTERS",
    MapSchema = "MAP_SCHEMA",
}

export const USER_PREFERENCE_SIMPLE_LIST_SCHEMA: SimpleListSchema<UserPreference> =
    {
        name: {
            label: "Name",
        },
        createdAt: {
            label: "Created at",
            display: (e) => dateToLocaleString(e.createdAt, true),
        },
        updatedAt: {
            label: "Updated at",
            display: (e) =>
                e.updatedAt ? dateToLocaleString(e.updatedAt, true) : "",
        },
    };
