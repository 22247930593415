import { useTranslation } from "react-i18next";
import { BRANCH_SIMPLE_LIST_SCHEMA, Branch } from "../../models/branch";
import { Company } from "../../models/company";
import { SimpleList } from "../../sg-react/data";
import { LinkIcon, LocationIcon, PhoneIcon } from "../../sg-react/icons";
import { Accordion } from "../../sg-react/ui";
import './index.scss';

interface CompanyCardProps {
    company: Company;
}

const CompanyCard = ({ company }: CompanyCardProps) => {
    const { t } = useTranslation();
    return (
        <div className="company-card">
            <div className="company-card-header">
                <div className="company-card-name">{company.name}</div>
                {!!company?.logo && <img src={company.logo} alt={company.name} />}
            </div>
            <div className="company-card-content">
                <div className="company-card-informations">
                    {!!company.website && <a href={company.website} className="company-card-website"><LinkIcon /><span>{company.website}</span></a>}
                    {!!company.address && <div><LocationIcon /><span>{company.address}</span></div>}
                    {!!company.localPhone && <div><PhoneIcon /><span>{company.localPhone}</span></div>}
                    {!!company.internationalPhone && <div><PhoneIcon /><span>{company.internationalPhone}</span></div>}
                </div>
            </div>
            <Accordion items={[{
                key: 'all',
                label: t('branches:label'),
                component: (
                    <SimpleList<Branch>
                        data={company.branches}
                        primaryKey="id"
                        schema={BRANCH_SIMPLE_LIST_SCHEMA}
                    />
                )
            }]} />
        </div>
    )
}

export default CompanyCard;