import { ReactNode, useEffect, useState } from "react";
import './index.scss';

export interface DonutProps {
    values: { value: number, color?: string, className?: string }[];
    size?: number;
    withTotal?: boolean;
    withLabels?: boolean;
}

const Donut = ({ values, size, withTotal, withLabels }: DonutProps) => {
    const [arcs, setArcs] = useState<ReactNode[]>([]);

    const getArcs = (_values: { value: number, color?: string, className?: string }[]): ReactNode[] => {
        const total = Object.values(values).reduce((t, v) => t + v.value, 0);

        if (values.length === 0 || total === 0) {
            return [
                <circle
                    key="circle-empty"
                    cx="0"
                    cy="0"
                    r="1"
                    stroke="white"
                />
            ]
        }

        if (values.length === 1) {
            return [
                <circle
                    key="nadcap"
                    cx="0"
                    cy="0"
                    r="1"
                    {...(values[0].className ? { className: values[0].className } : { stroke: values[0].color })}
                />
            ]
        }

        let cumulativeRadians = 0;

        const _arcs = values.filter(({ value }) => value > 0).map(({ value, color, className }) => {
            const percent = value / total;

            const startX = Math.cos(cumulativeRadians);
            const startY = Math.sin(cumulativeRadians);

            cumulativeRadians += 2 * Math.PI * percent;

            const endX = Math.cos(cumulativeRadians);
            const endY = Math.sin(cumulativeRadians);

            const largeArcFlag = percent > 0.5 ? 1 : 0;

            const d = [
                `M ${startX} ${startY}`,
                `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`
            ].join(` `);

            return <path
                key={color ?? className}
                d={d}
                {...(className ? { className } : { stroke: color })}
            />;
        });

        if (withTotal) {
            _arcs.push(<circle cx="0" cy="0" r="0.85" fill="white" />);
            _arcs.push(<text x="0" y="0.1" dominantBaseline="middle" textAnchor="middle">{total}</text>);
        }

        return _arcs;
    }

    useEffect(() => {
        setArcs(getArcs(values));
    }, [values]);

    return (
        <svg
            width={size ?? "100%"}
            height={size ?? "100%"}
            viewBox="-1.2 -1.2 2.4 2.4"
            className="donut"
        >
            {arcs}
        </svg>
    )
}

export default Donut;