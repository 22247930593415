import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Nothing } from "../../sg-react/ui";
import './index.scss';
import { Entity } from "../../models/enums";
import BranchCard from "../../components/BranchCard";
import { BranchNadcap } from "../../models/branch";
import { Company } from "../../models/company";
import { DataCardList } from "../../sg-react/data";
import { Specification } from "../../models/specification";
import CompanyCard from "../../components/CompanyCard";
import SpecificationCard from "../../components/SpecificationCard";
import { PlusIcon } from "../../sg-react/icons";

interface UserDataProps {
    type: Entity;
}

const UserData = ({ type }: UserDataProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div id="user-data">
            {type === Entity.Company
                ? (
                    <DataCardList<Company>
                        primaryKey="id"
                        withSearch
                        endpoint="/companies/user"
                        card={(e) => <CompanyCard company={e} />}
                        sort={[
                            { key: 'name', label: t('branches:name') },
                            { key: 'city', label: t('branches:city') },
                            { key: 'country', label: t('branches:country') },
                        ]}
                        initialPagination={{ sortBy: 'name' }}
                        globalActions={[{
                            label: t('actions:new'),
                            color: 'success',
                            icon: <PlusIcon />,
                            onClick: () => navigate('/data-management/company')
                        }]}
                    />
                ) : type === Entity.Branch ? (
                    <DataCardList<BranchNadcap>
                        primaryKey="id"
                        withSearch
                        endpoint="/branches/user"
                        card={(e) => <BranchCard branch={e} />}
                        sort={[
                            { key: 'name', label: t('branches:name') },
                            { key: 'city', label: t('branches:city') },
                            { key: 'country', label: t('branches:country') },
                        ]}
                        initialPagination={{ sortBy: 'name' }}
                        globalActions={[{
                            label: t('actions:new'),
                            color: 'success',
                            icon: <PlusIcon />,
                            onClick: () => navigate('/data-management/branch')
                        }]}
                    />
                ) : (
                    <DataCardList<Specification>
                        primaryKey="id"
                        withSearch
                        endpoint="/specifications/user"
                        card={(e) => <SpecificationCard specification={e} />}
                        sort={[
                            { key: 'specification', label: t('specifications:specification') },
                            { key: 'designation', label: t('specifications:designation') },
                        ]}
                        initialPagination={{ sortBy: 'specification' }}
                        globalActions={[{
                            label: t('actions:new'),
                            color: 'success',
                            icon: <PlusIcon />,
                            onClick: () => navigate('/data-management/specification')
                        }]}
                    />
                )}
        </div >
    )
}

export default UserData;