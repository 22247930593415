import { useTranslation } from "react-i18next";
import { Specification } from "../../models/specification";
import './index.scss';

interface SpecificationCardProps {
    specification: Specification;
}

const SpecificationCard = ({ specification }: SpecificationCardProps) => {
    const { t } = useTranslation();
    return (
        <div className="specification-card">
            <div className="specification-card-name">{specification.specification}</div>
            <div className="specification-card-designation">
                {!!specification.designation && <div><label>{t('specifications:designation')}</label>{specification.designation}</div>}
                {!!specification.designationKeywords && <div><label>{t('specifications:designationKeywords')}</label>{specification.designationKeywords}</div>}
                {!!specification.designationPrivate && <div><label>{t('specifications:designationPrivate')}</label>{specification.designationPrivate}</div>}
            </div>
            <div className={`specification-card-privacy specification-card-privacy-${specification.privacy?.toLowerCase()}`}>
                {specification.privacy}
            </div>
        </div>
    )
}

export default SpecificationCard;