import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Entity } from "../models/enums";
import { useAuthContext } from "../sg-react/context";
import { BadgeIcon, BuildingIcon, DocumentCheckedIcon, DocumentForwardIcon, DocumentHelpIcon, EditIcon, FactoryIcon, GlobeIcon, TubeIcon } from "../sg-react/icons";
import { LayoutLeftNav, LayoutSubNav, RouteError } from "../sg-react/ui";
import { Role } from "../sg-react/utils/enums";
import BranchesDataList from "./branches-datalist";
import CompaniesDataList from "./companies-datalist";
import UserDataCreate from "./data-management";
import Revisions from "./revisions";
import SpecificationsDataList from "./specifications-datalist";
import UserData from "./user-data";
import MainMap from "./main-map";

const getRoutesForRole = (role?: Role): RouteObject[] => {
    const navigation = [
        { key: 'map', path: '', icon: <GlobeIcon /> },
        { key: 'data-block' },
        { key: 'user-data', path: 'user-data', icon: <BadgeIcon /> },
        { key: 'revisions', path: 'revisions', icon: <EditIcon /> },
        { key: 'administration-block', roles: [Role.SuperAdmin] },
        { key: 'companies-administration', path: 'companies-administration', icon: <BuildingIcon />, roles: [Role.SuperAdmin] },
        { key: 'branches-administration', path: 'branches-administration', icon: <FactoryIcon />, roles: [Role.SuperAdmin] },
        { key: 'specifications-administration', path: 'specifications-administration', icon: <TubeIcon />, roles: [Role.SuperAdmin] }
    ]

    return [
        {
            path: "/",
            element: <LayoutLeftNav navigation={navigation} />,
            children: [
                {
                    path: '/*',
                    element: <RouteError />,
                },
                {
                    element: (
                        <LayoutSubNav
                            root="revisions"
                            navigation={[
                                { key: 'revisions-pending', path: '', icon: <DocumentHelpIcon /> },
                                { key: 'revisions-history', path: 'history', icon: <DocumentCheckedIcon /> },
                                { key: 'revisions-sent', path: 'sent', icon: <DocumentForwardIcon /> },
                            ]}
                        />
                    ),
                    children: [
                        {
                            path: 'revisions',
                            element: <Revisions type="pending" />,
                        },
                        {
                            path: 'revisions/history',
                            element: <Revisions type="history" />,
                        },
                        {
                            path: 'revisions/sent',
                            element: <Revisions type="sent" />,
                        }
                    ]
                },
                {
                    path: 'data-management/:type?/:id?',
                    element: <UserDataCreate />,
                },
                {
                    element: (
                        <LayoutSubNav
                            root="user-data"
                            navigation={[
                                { key: 'user-data-companies', path: '', icon: <BuildingIcon /> },
                                { key: 'user-data-branches', path: 'branches', icon: <FactoryIcon /> },
                                { key: 'user-data-specifications', path: 'specifications', icon: <TubeIcon /> },
                            ]}
                        />
                    ),
                    children: [
                        {
                            path: 'user-data',
                            element: <UserData type={Entity.Company} />,
                        },
                        {
                            path: 'user-data/branches',
                            element: <UserData type={Entity.Branch} />,
                        },
                        {
                            path: 'user-data/specifications',
                            element: <UserData type={Entity.Specification} />,
                        }
                    ]
                },
                ...(role && [Role.Admin, Role.SuperAdmin].includes(role) ? [
                    {
                        path: 'companies-administration',
                        element: <CompaniesDataList />,
                    },
                    {
                        path: 'specifications-administration',
                        element: <SpecificationsDataList />,
                    },
                    {
                        path: 'branches-administration',
                        element: <BranchesDataList />,
                    },
                ] : []),
                {
                    element: <MainMap />,
                    path: '/',
                },
            ],
        },
    ];
}

const App = () => {
    const { currentRole } = useAuthContext();

    return currentRole
        ? <RouterProvider router={createBrowserRouter(getRoutesForRole(currentRole))} />
        : null;

}

export default App;