import { useTranslation } from "react-i18next";
import { CreateEntity } from "..";
import { Switch } from "../../../sg-react/form";
import { StepComponentProps } from "../../../sg-react/ui/Stepper";

const ClaimStep = ({ onChange, value }: StepComponentProps<CreateEntity>) => {
    const { t } = useTranslation();

    return (
        <div id="data-management-claim">
            <Switch
                inline
                id="claim"
                items={[{ key: 'yes', label: t('form:yes') }, { key: 'no', label: t('form:no') }]}
                value={value.claim === true ? 'yes' : value.claim === false ? 'no' : undefined}
                onChange={(v) => onChange({ ...value, claim: v === 'yes' })}
            />
        </div>
    );
}

export default ClaimStep;