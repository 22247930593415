import { useMemo } from 'react';
import { BranchNadcap, SpecificationsCount } from '../../models/branch';
import { Nadcap } from '../../models/enums';
import { NADCAP_COLORS } from '../../shared/constants';
import './index.scss';

interface NadcapBarProps {
    specificationsCount: SpecificationsCount;
}

const NadcapBars = ({ specificationsCount }: NadcapBarProps) => {

    const max = useMemo(() => Math.max(...Object
        .values(Nadcap).map(nadcap => specificationsCount.nadcapCount[nadcap] ?? 0))
        , [specificationsCount]);

    const values = useMemo(() => Object
        .values(Nadcap)
        .map(nadcap => ({
            key: nadcap,
            value: specificationsCount.nadcapCount[nadcap] ?? 0,
            color: NADCAP_COLORS[nadcap]
        }))
        , [specificationsCount]);

    return (
        <div className="nadcap-bars">
            <div className="nadcap-bars-bars">
                {values.map(v => (
                    <div
                        key={v.key as string}
                        className="nadcap-bars-bar"
                        style={{ height: (v.value * 100 / max) + '%', backgroundColor: v.color }}
                    >
                        {v.value}
                    </div>
                ))}
            </div>
            <div className="nadcap-bars-labels">
                {values.map(v => <div key={v.key as string}>{v.key}</div>)}
            </div>
        </div>
    )
}

export default NadcapBars;