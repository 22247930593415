import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompilationLink } from '../../models/compilation';
import { SimpleList } from '../../sg-react/data';
import AxiosInstance from '../../sg-react/services/Axios';
import EntitySearch from '../../sg-react/data/EntitySearch';
import './index.scss';
import { SimpleListSchema } from '../../sg-react/data/SimpleList';
import { useRequest } from '../../sg-react/hooks';

const SIMPLE_LIST_SCHEMA_LINK: SimpleListSchema<CompilationLink> = {
    process: {
        label: 'Process',
    },
    valid: {
        label: 'Valid',
        display: 'toggle',
    },
    unlink: {
        label: 'Unlink',
        display: 'checkbox'
    },
};

interface CompilationLinksProps<T extends CompilationLink> {
    endpoint: string;
    id?: number;
    schema: SimpleListSchema<T>;
    data?: T[];
    onChange: (c: T[]) => void;

}

const CompilationLinks = <T extends CompilationLink>({
    endpoint,
    id,
    schema,
    data,
    onChange,
}: CompilationLinksProps<T>) => {
    const { t } = useTranslation();
    const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);
    const request = useRequest();

    const handleRowChange = useCallback((link: T) => {
        const _data = [...(data ?? [])];
        const index = _data.findIndex(d => d.id === link.id);

        if (index >= 0) {
            _data[index] = link;
            onChange(_data);
        }
    }, [data, onChange]);

    const handleAddCompiled = useCallback((compiled: T[]) => {
        if (!compiled?.length) return;

        const compiledToAdd: T[] = [];

        for (const c of compiled) {
            if (!data?.some(d => d.id === c.id)) {
                compiledToAdd.push({ ...c, valid: true });
            }
        }
        onChange([...(data ?? []), ...compiledToAdd]);
        setAddModalVisible(false);
    }, [data, onChange]);

    useEffect(() => {
        if (id) {
            request.get<T[]>(endpoint + '/compilation-links/' + id)
                .then(onChange)
                .catch(() => null);
        }
    }, [id, endpoint]);

    return (
        <div className="compilation-links">
            <div className="compilation-links-title">{t('compilation:linked')}</div>
            <div className="compilation-links-description">{t('compilation:linked_helper')}</div>
            <SimpleList<T>
                data={data}
                primaryKey="id"
                schema={{ ...schema, ...SIMPLE_LIST_SCHEMA_LINK }}
                actions={{ change: handleRowChange, add: () => setAddModalVisible(true) }}
            />
            <EntitySearch<T>
                visible={isAddModalVisible}
                endpoint={endpoint + '/compilation'}
                primaryKey="id"
                schema={schema}
                onSubmit={handleAddCompiled}
                onClose={() => setAddModalVisible(false)}
            />
        </div>
    )
}

export default CompilationLinks;