// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#revisions {
  flex-grow: 1;
  justify-content: center;
  display: flex;
}
#revisions > .data-card-list {
  height: 100%;
}
#revisions .comment-modal {
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/pages/revisions/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,uBAAA;EACA,aAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,YAAA;AAAR","sourcesContent":["#revisions {\n    flex-grow: 1;\n    justify-content: center;\n    display: flex;\n\n    & > .data-card-list {\n        height: 100%;\n    }\n\n    & .comment-modal {\n        width: 400px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
