import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BRANCH_VALIDATION } from "../../models/branch";
import { COMPANY_VALIDATION } from "../../models/company";
import { Entity } from "../../models/enums";
import { SPECIFICATION_VALIDATION, Specification } from "../../models/specification";
import { ObjectDiff } from "../../sg-react/data";
import { useRequest } from "../../sg-react/hooks";
import { validateEntity } from "../../sg-react/hooks/useForm";
import { BubbleIcon, CrosshairIcon, HandIcon, MagnifierIcon, PencilIcon } from "../../sg-react/icons";
import Stepper, { Step } from "../../sg-react/ui/Stepper";
import './index.scss';
import ClaimStep from "./steps/ClaimStep";
import CommentStep from "./steps/CommentStep";
import EditStep from "./steps/EditStep";
import SearchStep from "./steps/SearchStep";
import SelectTypeStep from "./steps/SelectTypeStep";
import { Revision } from "../../models/revision";

const STEPS: Step<CreateEntity>[] = [
    {
        key: 'type',
        description: 'type_description',
        icon: <CrosshairIcon />,
        validation: (e: Partial<CreateEntity>): boolean => !!e.type,
        component: SelectTypeStep,
        validationComponent: ({ value }) => <span>{value.type}</span>
    }, {
        key: 'search',
        description: 'search_description',
        icon: <MagnifierIcon />,
        validation: (e: Partial<CreateEntity>): boolean => !!e.entity,
        component: SearchStep,
        validationComponent: ({ value }) => <span>{value.entity?.name ?? 'New'}</span>
    }, {
        key: 'edit',
        icon: <PencilIcon />,
        validation: (e: Partial<CreateEntity>): boolean => !!e.entityEdited,
        component: EditStep,
        onSubmit: (e: Partial<CreateEntity>) => {
            if (e.type === Entity.Branch) {
                return validateEntity(e.entityEdited, BRANCH_VALIDATION);
            } else if (e.type === Entity.Specification) {
                return validateEntity(e.entityEdited, SPECIFICATION_VALIDATION);
            } else {
                return validateEntity(e.entityEdited, COMPANY_VALIDATION);
            }
        },
        validationComponent: ({ value }) => (
            <ObjectDiff
                current={value.entity}
                updated={value.entityEdited}
                mappers={{ specifications: (e: Specification[] | undefined) => e?.length ? `${e[0].specification} (${e[0].designation})` : '' }}
            />
        )
    }, {
        key: 'claim',
        description: 'claim_description',
        icon: <HandIcon />,
        validation: (e: Partial<CreateEntity>): boolean => e.claim !== undefined,
        component: ClaimStep,
        validationComponent: ({ value }) => <span>{value.claim ? 'Yes' : 'No'}</span>
    }, {
        key: 'comment',
        description: 'comment_description',
        icon: <BubbleIcon />,
        validation: (e: Partial<CreateEntity>): boolean => !!e.comment,
        component: CommentStep,
        validationComponent: ({ value }) => <span>{value.comment}</span>
    }
];

export type CreateEntity = {
    type: Entity;
    entity: any;
    entityEdited: any;
    claim: boolean;
    comment: string;
}

const DataManagementTunnel = () => {
    const [initialValue, setInitialValue] = useState<Partial<CreateEntity>>({});
    const { type, id } = useParams();
    const [isInit, setInit] = useState<boolean>(false);
    const { get, post } = useRequest();
    const navigate = useNavigate();

    const handleSubmit = useCallback(async (e: Partial<CreateEntity>) => {
        const dto = {
            entityType: e.type,
            entityId: e.entityEdited?.id,
            entity: e.entityEdited,
            claim: !!e.claim,
            comment: e.comment,
        };
        post<Revision>('revisions', dto, {
            errorMessage: 'error.server_error',
            successMessage: dto.entityId ? 'success.update' : 'success.create',
            loader: true,
            i18n: true
        })
            .then(() => navigate('/revisions/sent'))
            .catch(() => null)
    }, []);

    useEffect(() => {
        if (type && Object.values(Entity).includes(type as Entity)) {
            if (!id || id === 'create') {
                setInitialValue({ type: type as Entity });
                setInit(true);
            } else {
                const url = type === 'branch' ? 'branches' : type === 'company' ? 'companies' : 'specifications';
                get<any>(`${url}/${id}`, {
                    errorMessage: 'error.server_error',
                    i18n: true
                }).then((e) => {
                    setInitialValue({ type: type as Entity, entity: e });
                    setInit(true);
                }).catch(() => {
                    setInitialValue({ type: type as Entity });
                    setInit(true);
                });
            }
        } else {
            setInitialValue({});
            setInit(true);
        }
    }, [type, id]);

    if (!isInit) return null;

    return (
        <Stepper
            initialValue={initialValue}
            steps={STEPS}
            i18n="data_management"
            onSubmit={handleSubmit}
        />
    )
}

export default DataManagementTunnel;