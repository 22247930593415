import { useTranslation } from "react-i18next";
import CompanyForm from "../../components/CompanyForm";
import { COMPANY_LIST_SCHEMA, COMPANY_VALIDATION, Company } from "../../models/company";
import { DataList } from "../../sg-react/data";

const CompaniesDataList = () => {
    const { t } = useTranslation();

    return (
        <DataList<Company>
            title={t('companies:title')}
            schema={COMPANY_LIST_SCHEMA}
            primaryKey="id"
            validation={COMPANY_VALIDATION}
            actions={{ merge: true, view: true }}
            endpoint="/companies"
            updateForm={CompanyForm}
            createForm={CompanyForm}
        />
    );
}

export default CompaniesDataList;