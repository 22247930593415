export enum Nadcap {
    Aqs = "AQS",
    Cp = "CP",
    Comp = "COMP",
    Ht = "HT",
    Mtl = "MTL",
    Ndt = "NDT",
    Se = "SE",
    Wld = "WLD",
    Nq = "NQ",
}
export enum NadcapFull {
    Aqs = "AQS",
    Asa = "ASA",
    Cp = "CP",
    Ct = "CT",
    Comp = "COMP",
    Cmsp = "CMSP",
    Seal = "SEAL",
    Etg = "ETG",
    Flu = "FLU",
    HT = "HT",
    Mtl = "MTL",
    Mi = "MI",
    Mmm = "MMM",
    Na = "NA",
    Nmse = "NMSE",
    Ndt = "NDT",
    Nmmm = "NMMM",
    Nmmt = "NMMT",
    Slt = "SLT",
    Wld = "WLD",
}

/* 


Aerospace Quality Systems (AQS)
Aero Structure Assembly (ASA)
Chemical Processing (CP)
Coatings (CT)
Composites (COMP)
Conventional Machining as a Special Process (CMSP)
Elastomer Seals (SEAL)
Electronics (ETG)
Fluids Distribution (FLU)
Heat Treating (HT)
Materials Testing Laboratories (MTL)
Measurement & Inspection (M&I)
Metallic Materials Manufacturing (MMM)
Nonconventional Machining and Surface Enhancement (NMSE)
Nondestructive Testing (NDT)
Non Metallic Materials Manufacturing (NMMM)
Non Metallic Materials Testing (NMMT)
Sealants (SLT)
Welding (WLD) */

export enum BusinessStatus {
    Operational = "OPERATIONAL",
    ClosedPermanently = "CLOSED_PERMANENTLY",
    ClosedTemporarily = "CLOSED_TEMPORARILY",
}

export enum Entity {
    Branch = "branch",
    Specification = "specification",
    Company = "company",
}

export enum RevisionStatus {
    Pending = "PENDING",
    Approved = "APPROVED",
    Rejected = "REJECTED",
}

export enum Privacy {
    Public = "PUBLIC",
    Protected = "PROTECTED",
    Private = "PRIVATE",
}
