import { useTranslation } from "react-i18next";
import SpecificationForm from "../../components/SpecificationForm";
import { Nadcap } from "../../models/enums";
import { SPECIFICATION_VALIDATION, Specification } from "../../models/specification";
import { DataList } from "../../sg-react/data";
import { ValidationRule } from "../../sg-react/hooks/useForm";
import { DataListSchema } from "../../sg-react/data/DataList";

const LIST_SCHEMA: DataListSchema<Specification> = {
    id: {
        label: 'ID',
        width: '10%',
        options: {
            order: true,
        }
    },
    specification: {
        label: 'Specification',
        display: 'textinput',
        width: '20%',
        options: {
            order: true,
            startsWith: true,
            edit: true,
        }
    },
    nadcapCommodity: {
        label: 'Nadcap commodity',
        display: 'select-multiple',
        values: Object.values(Nadcap) as Nadcap[],
        width: '20%',
        options: {
            order: true,
            in: true,
            edit: true,
            null: true,
        }
    },
    designation: {
        label: 'Designation',
        display: 'textinput',
        width: '50%',
        options: {
            order: true,
            search: true,
            edit: true,
            null: true,
        }
    }
}

const SpecificationsDataList = () => {
    const { t } = useTranslation();

    return (
        <DataList
            title={t('specifications:title')}
            schema={LIST_SCHEMA}
            primaryKey="id"
            validation={SPECIFICATION_VALIDATION}
            actions={{ merge: true, view: true }}
            endpoint="/specifications"
            createForm={SpecificationForm}
            updateForm={SpecificationForm}
        />
    );
}

export default SpecificationsDataList;