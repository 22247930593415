import { useMemo } from "react";
import { BranchNadcap, SpecificationsCount } from "../../models/branch";
import { Nadcap } from "../../models/enums";
import Pie, { PieProps } from "../../sg-react/data/Pie";
import { NADCAP_COLORS } from "../../shared/constants";
import './index.scss';

interface NadcapPieProps extends Omit<PieProps, 'values' | 'total' | 'withCenter'> {
    branch: BranchNadcap;
}

const MIN_SIZE = 24;
const MIN_SIZE_TOTAL = 10;
const MAX_SIZE = 50;
const MAX_SIZE_TOTAL = 50;

const NadcapPie = ({ branch, size, ...rest }: NadcapPieProps) => {
    const total = useMemo(() => branch.specifications?.length ?? 0, [branch]);
    const values = useMemo(() => Object
        .values(Nadcap)
        .map(nadcap => ({
            value: branch.specifications?.filter(s => s.nadcapCommodity?.includes(nadcap)).length ?? 0,
            color: NADCAP_COLORS[nadcap]
        }))
        , [branch]);

    const dynamicSize = useMemo(() => {
        const limited_total = Math.max(Math.min(total, MAX_SIZE_TOTAL), MIN_SIZE_TOTAL);
        const distance = limited_total - MIN_SIZE_TOTAL;
        return MIN_SIZE + ((MAX_SIZE - MIN_SIZE) * distance / (MAX_SIZE_TOTAL - MIN_SIZE_TOTAL));
    }, [total]);

    return (
        <Pie
            values={values}
            size={size !== undefined ? size : dynamicSize}
            total={total}
            withCenter
            {...rest}
        />
    )
}

export default NadcapPie;