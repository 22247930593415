import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { CreateEntity } from "..";
import { Branch } from "../../../models/branch";
import { Entity } from "../../../models/enums";
import { Specification } from "../../../models/specification";
import Search, { SearchResult } from "../../../sg-react/data/Search";
import { RadioList } from "../../../sg-react/form";
import { StepComponentProps } from "../../../sg-react/ui/Stepper";
import { Company } from "../../../models/company";

const SearchStep = ({ onChange, value }: StepComponentProps<CreateEntity>) => {
    const [result, setResult] = useState<SearchResult<any> | null>(null);
    const [use, setUse] = useState<'new' | 'existing' | null>(null);

    const SearchComponent = useMemo(() => {
        if (value.type === Entity.Branch) {
            return (
                <Search<Branch>
                    placeholder={t('branches:search')}
                    endpoint={`/branches/search`}
                    onClick={setResult}
                />
            );
        } else if (value.type === Entity.Specification) {
            return (
                <Search<Specification>
                    placeholder={t('specifications:search')}
                    endpoint={`/specifications/search`}
                    onClick={setResult}
                />
            );
        } else if (value.type === Entity.Company) {
            return (
                <Search<Company>
                    placeholder={t('companies:search')}
                    endpoint={`/companies/search`}
                    onClick={setResult}
                />
            );
        } else {
            return null;
        }
    }, [value]);

    useEffect(() => {
        if (result) {
            setUse('existing');
        }
    }, [result]);

    useEffect(() => {
        if (use) {
            onChange({ type: value.type, entity: use === 'new' ? {} : result?.entity })
        }
    }, [use]);

    return (
        <div id="data-management-search">
            {SearchComponent}
            <RadioList<'new' | 'existing'>
                inline
                id="use"
                value={use ?? undefined}
                items={!result
                    ? [{ key: 'new', label: t('actions:create') }]
                    : [{ key: 'new', label: t('actions:create') }, { key: 'existing', label: `${t('actions:use')} '${result.label}'` }]
                }
                onChange={(v) => setUse(v ?? null)}
            />
        </div>
    )
}

export default SearchStep;