import { useState } from "react";
import { Nadcap, Privacy } from "../../models/enums";
import { Specification, SpecificationCompiled } from "../../models/specification";
import { DataListFormProps } from "../../sg-react/data/DataList";
import { SimpleListSchema } from "../../sg-react/data/SimpleList";
import { Select, Switch, TextField } from "../../sg-react/form";
import CompilationLinks from "../CompilationLinks";
import './index.scss';
import { Modal } from "../../sg-react/ui";

const SIMPLE_LIST_SCHEMA: SimpleListSchema<SpecificationCompiled> = {
    id: {
        label: 'ID'
    },
    specification: {
        label: 'Specification',
    },
    nadcap_commodity: {
        label: 'Nadcap commodity',
    },
    designation: {
        label: 'Designation',
    }
};

const SpecificationForm = ({ entity, onChange, attachInput, revision }: { revision?: boolean } & DataListFormProps<Specification>) => {
    const [linkedEntitiesModalVisible, setLinkedEntitiesModalVisible] = useState<boolean>(false);

    return (
        <div id="specification-form">
            <TextField inline small label i18n="specifications" {...attachInput('specification')} />
            <Select inline small multiple label i18n="specifications" items={Object.values(Nadcap)} {...attachInput('nadcapCommodity')} />
            <Switch inline small label tooltip i18n="specifications" items={Object.values(Privacy)} {...attachInput('privacy')} />
            <TextField inline small label i18n="specifications" {...attachInput('designation')} />
            <TextField inline small label tooltip i18n="specifications" {...attachInput('designationKeywords')} />
            <TextField inline small label tooltip i18n="specifications" {...attachInput('designationPrivate')} />
            {linkedEntitiesModalVisible && (
                <Modal onClose={() => setLinkedEntitiesModalVisible(false)}>
                    <CompilationLinks<SpecificationCompiled>
                        data={entity.compiledEntities}
                        endpoint="/specifications"
                        id={entity.id}
                        schema={SIMPLE_LIST_SCHEMA}
                        onChange={(d) => onChange('compiledEntities', d)}
                    />
                </Modal>
            )}
        </div>
    )
}

export default SpecificationForm;