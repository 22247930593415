import { CreateEntity } from "..";
import { TextArea } from "../../../sg-react/form";
import { StepComponentProps } from "../../../sg-react/ui/Stepper";

const CommentStep = ({ value, onChange }: StepComponentProps<CreateEntity>) => {
    return (
        <div id="data-management-comment">
            <TextArea
                inline
                rows={10}
                id="comment"
                value={value.comment}
                onChange={(v) => onChange({ ...value, comment: v })}
            />
        </div>
    )
}

export default CommentStep;