import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BranchNadcap } from "../../models/branch";
import { BuildingIcon, CloseIcon, LinkIcon, LocationIcon, PhoneIcon } from "../../sg-react/icons";
import { Accordion } from "../../sg-react/ui";
import NadcapBars from "../NadcapBars";
import SpecificationList from "../SpecificationList";
import './index.scss';

interface BranchCardProps {
    branch: BranchNadcap;
    filtered?: boolean;
    onClose?: () => void;
}

const BranchCard = ({ branch, filtered, onClose }: BranchCardProps) => {
    const { t } = useTranslation();

    const accordionItems = useMemo(() => filtered ? [{
        key: 'filtered',
        label: t('specifications:specifications_filtered'),
        component: <SpecificationList specifications={branch.specifications} />
    }, {
        key: 'all',
        label: t('specifications:specifications_all'),
        component: <SpecificationList endpoint={`/branches/${branch.id}/specifications`} />
    }] : [{
        key: 'all',
        label: t('specifications:label'),
        component: <SpecificationList endpoint={`/branches/${branch.id}/specifications`} />
    }], [branch, filtered]);

    return (
        <div className="branch-card">
            <div className="branch-card-header">
                <div className="branch-card-name">{branch.name}</div>
                {!!branch.company?.logo && <img src={branch.company.logo} alt={branch.company.name} />}
                {!!onClose && <CloseIcon onClick={onClose} />}
            </div>
            <div className="branch-card-content">
                <div className="branch-card-informations">
                    {!!branch.company && <div><BuildingIcon /><span>{branch.company.name}</span></div>}
                    {!!branch.website && <a href={branch.website} className="branch-card-website"><LinkIcon /><span>{branch.website}</span></a>}
                    {!!branch.address && <div><LocationIcon /><span>{branch.address}</span></div>}
                    {!!branch.localPhone && <div><PhoneIcon /><span>{branch.localPhone}</span></div>}
                    {!!branch.internationalPhone && <div><PhoneIcon /><span>{branch.internationalPhone}</span></div>}
                </div>
                {!!branch.specificationsCount && (<NadcapBars specificationsCount={branch.specificationsCount} />)}
            </div>
            <Accordion items={accordionItems} />
            <div className="branch-card-links">
                <Link to={`/data-management/branch/${branch.id}`}>{t('revisions:propose')}</Link>
                <Link to={`/data-management/branch/${branch.id}`}>{t('branches:claim_link')}</Link>
            </div>
        </div>
    )
}

export default BranchCard;