import { SimpleListSchema } from "../sg-react/data/SimpleList";
import { EntityValidation, ValidationRule } from "./../sg-react/hooks/useForm";
import { Company } from "./company";
import { CompilationLink } from "./compilation";
import { Nadcap } from "./enums";
import { Specification } from "./specification";

export type Branch = {
    id: number;
    name: string;
    streetNumber?: string;
    route?: string;
    city?: string;
    region?: string;
    country?: string;
    postalCode?: string;
    address?: string;
    localPhone?: string;
    internationalPhone?: string;
    businessStatus?: string;
    website?: string;
    lat?: number;
    lng?: number;
    specifications?: Specification[];
    compiledEntities?: BranchCompiled[];
    company?: Company;
};

export interface SpecificationsCount {
    count: number;
    nadcapCount: { [key in Nadcap]?: number };
}

export interface BranchNadcap extends Branch {
    lat: number;
    lng: number;
    specificationsCount?: SpecificationsCount;
    stepColor?: string;
    stepName?: string;
}

export interface BranchCompiled extends CompilationLink {
    id: number;
    name: string;
    address?: string;
    zipcode?: string;
    city?: number;
    country?: number;
}

export const BRANCH_VALIDATION: EntityValidation<Branch> = {
    name: [{ rule: ValidationRule.Required }],
    businessStatus: [{ rule: ValidationRule.Required }],
    address: [{ rule: ValidationRule.Required }],
    lat: [{ rule: ValidationRule.Required }, { rule: ValidationRule.Number }],
    lng: [{ rule: ValidationRule.Required }, { rule: ValidationRule.Number }],
};

export const BRANCH_SIMPLE_LIST_SCHEMA: SimpleListSchema<Branch> = {
    name: {
        label: "Name",
    },
    city: {
        label: "City",
    },
    country: {
        label: "Country",
    },
};
