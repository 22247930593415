import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BRANCH_SIMPLE_LIST_SCHEMA, Branch } from "../../models/branch";
import { Company } from "../../models/company";
import { SimpleList } from "../../sg-react/data";
import { DataListFormProps } from "../../sg-react/data/DataList";
import EntitySearch from "../../sg-react/data/EntitySearch";
import { AddressPicker, TextField } from "../../sg-react/form";
import ImageField from "../../sg-react/form/ImageField";
import { Group } from "../../sg-react/ui";
import './index.scss';

const CompanyForm = ({ entity, onChange, attachInput, onMultipleChange, revision }: { revision?: boolean } & DataListFormProps<Company>) => {
    const { t } = useTranslation();
    const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);

    const handleAddBranches = useCallback((branchesToAdd: Branch[]) => {
        if (!branchesToAdd?.length) return;

        const _branches = [...(entity.branches ?? []), ...branchesToAdd.filter(s => !entity.branches?.some(s2 => s2.id === s.id))];
        onChange('branches', _branches);
        setAddModalVisible(false);
    }, [entity, onChange]);

    const handleDeleteBranches = useCallback((branchesToDelete: Branch[]) => {
        if (!entity.branches?.length || !branchesToDelete?.length) return;

        const _branches = entity.branches.filter(s1 => !branchesToDelete.some(s2 => s1.id === s2.id));
        onChange('branches', _branches);
    }, [entity, onChange]);

    return (
        <div id="company-form">
            <div id="company-form-details">
                <div id="company-form-informations">
                    <TextField small label={t('companies:name')} {...attachInput('name')} />
                    <TextField small label={t('companies:website')} {...attachInput('website')} />
                    <div className="flex-row-equal-cols">
                        <TextField small label={t('companies:local_phone')} {...attachInput('localPhone')} />
                        <TextField small label={t('companies:international_phone')} {...attachInput('internationalPhone')} />
                    </div>
                </div>
                <AddressPicker id="address" label i18n="companies" entity={entity} onGeocode={onMultipleChange} small  {...attachInput('address')} />
                <ImageField small label={t('companies:logo')} endpoint="/companies/logo" {...attachInput('logo')} />
            </div>
            <Group label={t('branches:label')}>
                <SimpleList<Branch>
                    data={entity.branches}
                    primaryKey="id"
                    schema={BRANCH_SIMPLE_LIST_SCHEMA}
                    actions={{ add: () => setAddModalVisible(true), deleteMultiple: handleDeleteBranches }}
                />
                <EntitySearch<Branch>
                    visible={isAddModalVisible}
                    endpoint={'/branches'}
                    primaryKey="id"
                    schema={BRANCH_SIMPLE_LIST_SCHEMA}
                    onSubmit={handleAddBranches}
                    onClose={() => setAddModalVisible(false)}
                />
            </Group>
        </div>
    )
}

export default CompanyForm;