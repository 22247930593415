import { DndContext, DragEndEvent, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Donut from "../../components/Donut";
import { Nadcap } from "../../models/enums";
import { Specification } from "../../models/specification";
import { USER_PREFERENCE_SIMPLE_LIST_SCHEMA, UserPreference, UserPreferenceType } from "../../models/user";
import { ButtonsFilter, SearchFilter } from "../../sg-react/data/DataFilters";
import { SearchResultItem } from "../../sg-react/data/Search";
import { ColorPicker, Switch, TextField } from "../../sg-react/form";
import { EraseIcon, FiltersHorizontalIcon, GlobeWireIcon, LocationIcon, MagnifierIcon, SettingIcon, TrashIcon, TubeIcon } from "../../sg-react/icons";
import { Geoname } from "../../sg-react/models/geo";
import { Button, Presets } from "../../sg-react/ui";
import { COLORS } from "../../sg-react/utils/constants";
import { NADCAP_COLORS } from "../../shared/constants";
import { Filters } from ".";

export interface MapFiltersValues {
    geonames?: Omit<SearchResultItem<Geoname>, 'entity'>[];
    nadcap?: Nadcap[];
    specifications?: Omit<SearchResultItem<Specification>, 'entity'>[];
    color?: string;
    name?: string;
    id: number;
}

interface MapFiltersContentProps {
    filters: MapFiltersValues;
    onChange: (f: MapFiltersValues) => void;
    forStep?: boolean;
}

export const MapFiltersContent = ({ filters, onChange, forStep }: MapFiltersContentProps) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="filters-content">
            {!!forStep && (
                <div className="filters-step-name">
                    <TextField
                        inline
                        small
                        id="name"
                        value={filters?.name}
                        onChange={(name) => onChange({ ...filters, name })}
                    />
                    <ColorPicker
                        small
                        inline
                        colors={COLORS}
                        id="color"
                        value={filters?.color}
                        onChange={(color) => onChange({ ...filters, color })}
                    />
                </div>
            )}
            <div className="filters-presets">
                <SettingIcon />
                <span>{t('ui:presets')}</span>
                <Presets<UserPreference>
                    getEndpoint={`/users/preferences/${UserPreferenceType.MapFilters}`}
                    crudEndpoint="/users/preferences"
                    schema={USER_PREFERENCE_SIMPLE_LIST_SCHEMA}
                    initial={{ type: UserPreferenceType.MapFilters }}
                    primaryKey="id"
                    value={filters}
                    onLoad={(u) => onChange({ ...u.data, name: u.name, id: filters.id, color: filters.color })}
                />
            </div>
            <SearchFilter<Geoname>
                icon={<LocationIcon />}
                label={t('geo:label')}
                placeholder={t('geo:search')}
                endpoint={`/geo/search/${i18n.language}`}
                withRadius
                withTags="geo"
                value={filters?.geonames}
                onChange={v => onChange({ ...filters, geonames: v.map(v => ({ ...v, entity: undefined })) })}
            />
            <ButtonsFilter<Nadcap>
                icon={<GlobeWireIcon />}
                label={t('nadcap:label')}
                buttons={
                    Object.values(Nadcap).map(nadcap => ({
                        key: nadcap,
                        label: t(`nadcap:${nadcap.toLowerCase()}`),
                        icon: <Donut values={[{ color: NADCAP_COLORS[nadcap], value: 100 }]} />
                    }))
                }
                value={filters?.nadcap}
                onChange={v => onChange({ ...filters, nadcap: v })}
            />
            <SearchFilter<Specification>
                icon={<TubeIcon />}
                label={t('specifications:label')}
                placeholder={t('specifications:search')}
                endpoint={`/specifications/search`}
                withTags
                withMatch
                value={filters?.specifications}
                additionalFilters={filters}
                onChange={v => onChange({ ...filters, specifications: v.map(v => ({ ...v, entity: undefined })) })}
            />
        </div>
    )
}

interface MapFiltersStepProps {
    filters: MapFiltersValues;
    onChange: (f: MapFiltersValues) => void;
    expanded?: boolean;
    onExpand: () => void;
    onDelete: () => void;
}

export const MapFiltersStep = ({ filters, onChange, onExpand, onDelete, expanded }: MapFiltersStepProps) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: filters.id });

    return (
        <div className="filters-step" ref={setNodeRef} style={{
            transform: CSS.Transform.toString(transform),
            transition
        }}>
            <div className="filters-step-content">
                <div className="filters-step-header" onClick={onExpand}>
                    <div
                        className="filters-step-color"
                        style={filters.color ? { backgroundColor: filters.color, boxShadow: `0 0px 12px 6px ${filters.color}30` } : undefined}
                        {...attributes} {...listeners}
                    />
                    <div className="filters-step-name">{filters.name}</div>
                    {!expanded && (!!filters?.geonames?.length || !!filters?.nadcap?.length || !!filters?.specifications?.length) && (
                        <div className="filters-step-filters">
                            {!!filters?.geonames?.length && <div className="filters-step-pill"><LocationIcon /><div>{filters.geonames.length}</div></div>}
                            {!!filters?.nadcap?.length && <div className="filters-step-pill"><GlobeWireIcon /><div>{filters.nadcap.length}</div></div>}
                            {!!filters?.specifications?.length && <div className="filters-step-pill"><TubeIcon /><div>{filters.specifications.length}</div></div>}
                        </div>
                    )}
                    {!!expanded && (
                        <TrashIcon onClick={onDelete} />
                    )}

                </div>
                {!!expanded && (
                    <div className="filters-step-form">
                        <MapFiltersContent filters={filters} onChange={onChange} forStep />
                    </div>
                )}
            </div>
        </div>
    );
}

interface MapFiltersProps {
    expanded: boolean;
    onSubmit: (f: Filters) => void;
}

const MapFilters = ({ onSubmit, expanded }: MapFiltersProps) => {
    const { t } = useTranslation();
    const [mode, setMode] = useState<'explore' | 'plan'>('explore');
    const [exploreFilters, setExploreFilters] = useState<MapFiltersValues>({ id: 1, name: 'explore' });
    const [currentPlanFilter, setCurrentPlanFilter] = useState<number | null>(null);
    const [planFilters, setPlanFilters] = useState<MapFiltersValues[]>([]);
    const sensors = useSensors(useSensor(PointerSensor));

    const handleSubmit = useCallback((steps: MapFiltersValues[], mode: 'plan' | 'explore') => {
        const filters = steps.map((f, i) => ({
            ...f,
            name: f.name ?? `${t('form:step')} ${i}`,
            geonames: f?.geonames?.map(g => ({ key: g.key, radius: g.radius })),
            nadcap: f?.nadcap,
            specifications: f?.specifications?.map(g => ({ key: g.key })),
        }));

        if (mode === 'explore') {
            localStorage.setItem('map-filters-explore', JSON.stringify(steps[0]));
        } else {
            localStorage.setItem('map-filters-plan', JSON.stringify(steps));
        }

        onSubmit({ mode, filters });
    }, [onSubmit]);

    const handleClear = useCallback((mode: 'explore' | 'plan') => {
        if (mode === 'explore') {
            setExploreFilters({ id: 1 });
        } else {
            setPlanFilters([]);
        }
    }, []);

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;

        if (over !== null && active.id !== over.id) {
            setPlanFilters(planFilters => {
                const oldIndex = planFilters.findIndex(f => f.id === active.id);
                const newIndex = planFilters.findIndex(f => f.id === over.id);

                return arrayMove(planFilters, oldIndex, newIndex);
            });
        }
    }, []);

    const handleChangePlanFiltersStep = useCallback((f: MapFiltersValues, i: number) => {
        if (i < 0 || i > planFilters.length) return;

        const _planFilters = [...planFilters];
        _planFilters[i] = f;
        setPlanFilters(_planFilters);
    }, [planFilters]);

    const handleDeletePlanFiltersStep = useCallback((i: number, current: number | null) => {
        if (i < 0 || i > planFilters.length) return;

        if (planFilters.length === 1) {
            setPlanFilters([{ name: `${t('form:step')} 1`, id: 1, color: COLORS[0] }])
        } else {
            const _planFilters = [...planFilters];
            _planFilters.splice(i, 1);
            setPlanFilters(_planFilters);
            if (current === i) {
                setCurrentPlanFilter(null);
            }
        }
    }, [planFilters]);

    const handleNewPlanFiltersStep = useCallback(() => {
        setPlanFilters((steps) => {
            setCurrentPlanFilter(steps.length);
            const id = steps.length ? Math.max(...steps.map(s => s.id)) + 1 : 1;
            return [...steps, { name: `${t('form:step')} ${id}`, id, color: COLORS[id % COLORS.length] }];
        })
    }, []);

    useEffect(() => {
        try {
            const values: MapFiltersValues = JSON.parse(localStorage.getItem('map-filters-explore') ?? '');
            setExploreFilters(values);
            if (mode === 'explore') {
                handleSubmit([values], 'explore');
            }
        } catch { }

        try {
            const steps: MapFiltersValues[] = JSON.parse(localStorage.getItem('map-filters-plan') ?? '');
            setPlanFilters(steps);
            if (mode === 'plan') {
                handleSubmit(steps, 'plan');
            }
        } catch { }
    }, []);

    return (
        <div id="map-filters-panel" className={`map-context-panel-item ${expanded ? 'expanded' : ''}`}>
            <div className="map-context-panel-item-header">
                <FiltersHorizontalIcon />
                <span>{t('filters:filters')}</span>
            </div>
            <div className="map-context-panel-item-content">
                <div id="filters-mode">
                    <Switch<'explore' | 'plan'> id="mode" small items={[{ key: 'explore', label: t('branches:explore') }, { key: 'plan', label: t('branches:plan') }]} value={mode} onChange={(v) => setMode(v ?? 'explore')} />
                </div>
                {mode === 'explore'
                    ? (
                        <MapFiltersContent filters={exploreFilters} onChange={setExploreFilters} />
                    ) : (

                        <div id="filters-steps">
                            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                <SortableContext items={planFilters.map(f => f.id)} strategy={verticalListSortingStrategy}>
                                    {planFilters.map((f, i) => (
                                        <MapFiltersStep
                                            key={f.id}
                                            filters={f}
                                            onChange={(v) => handleChangePlanFiltersStep(v, i)} expanded={i === currentPlanFilter}
                                            onExpand={() => setCurrentPlanFilter(i === currentPlanFilter ? null : i)}
                                            onDelete={() => handleDeletePlanFiltersStep(i, currentPlanFilter)}
                                        />
                                    ))}
                                </SortableContext>
                            </DndContext>
                            <div className="filters-step filters-step-add" onClick={handleNewPlanFiltersStep}>
                                <div className="filters-step-color" />
                                <div className="filters-step-name">{t('actions:add_step')}</div>
                            </div>
                        </div>
                    )}
            </div>
            <div className="map-context-panel-item-footer filters-actions">
                <Button color="secondary" onClick={() => handleClear(mode)} label={t('filters:clear')} icon={<EraseIcon />} />
                <Button color="primary" onClick={() => handleSubmit(mode === 'explore' ? [exploreFilters] : planFilters, mode)} label={t('filters:search')} icon={<MagnifierIcon />} />
            </div>
        </div>
    );
}

export default MapFilters;
