import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Branch, BranchCompiled } from "../../models/branch";
import { BusinessStatus } from "../../models/enums";
import { Specification } from "../../models/specification";
import { SimpleList } from "../../sg-react/data";
import { DataListFormProps } from "../../sg-react/data/DataList";
import EntitySearch from "../../sg-react/data/EntitySearch";
import { SimpleListSchema } from "../../sg-react/data/SimpleList";
import { AddressPicker, Select, TextField } from "../../sg-react/form";
import { Group, Modal } from "../../sg-react/ui";
import CompilationLinks from "../CompilationLinks";
import './index.scss';

const SPECIFICATION_SCHEMA: SimpleListSchema<Specification> = {
    id: {
        label: 'ID'
    },
    specification: {
        label: 'Specification',
    },
    nadcapCommodity: {
        label: 'Nadcap commodity',
    },
    designation: {
        label: 'Designation',
    }
};

const BRANCH_COMPILED_SCHEMA: SimpleListSchema<BranchCompiled> = {
    id: {
        label: 'ID'
    },
    name: {
        label: 'Name',
    },
    address: {
        label: 'Address',
    },
    zipcode: {
        label: 'Zipcode',
    },
    city: {
        label: 'City',
    },
    country: {
        label: 'Country',
    },
};

const BranchForm = ({ entity, onChange, attachInput, onMultipleChange, revision }: { revision?: boolean } & DataListFormProps<Branch>) => {
    const { t } = useTranslation();
    const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);
    const [linkedEntitiesModalVisible, setLinkedEntitiesModalVisible] = useState<boolean>(false);

    const handleAddSpecifications = useCallback((specificationsToAdd: Specification[]) => {
        if (!specificationsToAdd?.length) return;

        const _specifications = [...(entity.specifications ?? []), ...specificationsToAdd.filter(s => !entity.specifications?.some(s2 => s2.id === s.id))];
        onChange('specifications', _specifications);
        setAddModalVisible(false);
    }, [entity, onChange]);

    const handleDeleteSpecifications = useCallback((specificationsToDelete: Specification[]) => {
        if (!entity.specifications?.length || !specificationsToDelete?.length) return;

        const _specifications = entity.specifications.filter(s1 => !specificationsToDelete.some(s2 => s1.id === s2.id));
        onChange('specifications', _specifications);
    }, [entity, onChange]);

    return (
        <div id="branch-form">
            <div id="branch-form-public">
                <div id="branch-form-informations">
                    <TextField small label i18n="branches" {...attachInput('name')} />
                    <Select small items={Object.values(BusinessStatus)} label i18n="branches" {...attachInput('businessStatus')} />
                    <TextField small label i18n="branches" {...attachInput('website')} />
                    <div className="flex-row-equal-cols">
                        <TextField small label i18n="branches" {...attachInput('localPhone')} />
                        <TextField small label i18n="branches" {...attachInput('internationalPhone')} />
                    </div>
                    <AddressPicker id="address" label i18n="branches" entity={entity} onGeocode={onMultipleChange} small  {...attachInput('address')} />
                </div>
                <Group label={t('specifications:title')}>
                    <SimpleList<Specification>
                        data={entity.specifications}
                        primaryKey="id"
                        schema={SPECIFICATION_SCHEMA}
                        actions={{ add: () => setAddModalVisible(true), deleteMultiple: handleDeleteSpecifications }}
                    />
                    <EntitySearch<Specification>
                        visible={isAddModalVisible}
                        endpoint={'/specifications'}
                        primaryKey="id"
                        schema={SPECIFICATION_SCHEMA}
                        onSubmit={handleAddSpecifications}
                        onClose={() => setAddModalVisible(false)}
                    />
                </Group>
            </div>
            {!!entity.id && linkedEntitiesModalVisible && (
                <Modal onClose={() => setLinkedEntitiesModalVisible(false)}>
                    <CompilationLinks<BranchCompiled>
                        data={entity.compiledEntities}
                        id={entity.id}
                        endpoint="/branches"
                        schema={BRANCH_COMPILED_SCHEMA}
                        onChange={(d) => onChange('compiledEntities', d)}
                    />
                </Modal>
            )}
        </div>
    )
}

export default BranchForm;