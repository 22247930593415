import { t } from "i18next";
import { useCallback, useState } from "react";
import RevisionCard from "../../components/RevisionCard";
import { RevisionStatus } from "../../models/enums";
import { Revision } from "../../models/revision";
import DataCardList from "../../sg-react/data/DataCardList";
import CommentModal from "../../sg-react/form/CommentModal";
import { useRequest } from "../../sg-react/hooks";
import { CloseIcon, SuccessIcon } from "../../sg-react/icons";
import './index.scss';
import { useNavigate } from "react-router-dom";

interface RevisionsProps {
    type: 'pending' | 'history' | 'sent'
}

const Revisions = ({ type }: RevisionsProps) => {
    const [revision, setRevision] = useState<Revision | null>(null);
    const { patch } = useRequest();
    const navigate = useNavigate();

    const submitRevision = useCallback(async (comment?: string): Promise<void> => {
        if (!revision) return;

        patch<Revision>(
            '/revisions',
            { id: revision.id, status: revision.status, validationComment: comment, changes: revision.changes },
            { errorMessage: 'error.server_error', i18n: true, loader: true }
        )
            .then(() => {
                setRevision(null);
                navigate(0);
            })
            .catch(() => false);
    }, [revision, patch])

    return (
        <div id="revisions">
            <DataCardList<Revision>
                primaryKey="id"
                sort={[
                    { key: 'status', label: t('revisions:status') },
                    { key: 'createdAt', label: t('revisions:created_at') },
                    { key: 'createdBy', label: t('revisions:created_by') },
                    { key: 'validatedAt', label: t('revisions:validated_at') },
                    { key: 'validatedBy', label: t('revisions:validated_by') },
                ]}
                endpoint={`/revisions/${type}`}
                card={(e) => <RevisionCard revision={e} />}
                initialPagination={{ sortBy: 'createdAt', sortOrder: -1 }}
                actions={type === 'pending' ? [
                    {
                        label: 'Reject',
                        icon: <CloseIcon />,
                        color: 'error',
                        onClick: (e) => setRevision({ ...e, status: RevisionStatus.Rejected })
                    },
                    {
                        label: 'Approve',
                        icon: <SuccessIcon />,
                        color: 'success',
                        onClick: (e) => setRevision({ ...e, status: RevisionStatus.Approved })
                    }
                ] : undefined}
            />
            {!!revision && (
                <CommentModal onClose={() => setRevision(null)} onSumbit={(c) => submitRevision(c)} />
            )}
        </div>
    )
}

export default Revisions;