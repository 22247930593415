import { Nadcap } from "../models/enums";

export const NADCAP_COLORS = {
    [Nadcap.Aqs]: "#9e0142",
    [Nadcap.Cp]: "#d53e4f",
    [Nadcap.Comp]: "#fdae61",
    [Nadcap.Ht]: "#fee08b",
    [Nadcap.Mtl]: "#abdda4",
    [Nadcap.Ndt]: "#66c2a5",
    [Nadcap.Se]: "#3288bd",
    [Nadcap.Wld]: "#5e4fa2",
    [Nadcap.Nq]: "#989ba2",
};
