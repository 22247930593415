import { Fragment, useEffect, useState } from 'react';
import { BranchNadcap } from '../../models/branch';
import { BuildingIcon, LinkIcon, PhoneIcon } from '../../sg-react/icons';
import NadcapPie from '../NadcapPie';
import './index.scss';
import { useTranslation } from 'react-i18next';

interface BranchListProps {
    branches: BranchNadcap[];
    small?: boolean;
    onBranchClick?: (b: BranchNadcap) => void;
}

const BranchList = ({ branches: branchesFromProps, small, onBranchClick }: BranchListProps) => {
    const [branches, setBranches] = useState<BranchNadcap[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        setBranches(branchesFromProps);
    }, [branchesFromProps]);

    return (
        <div className={`branch-list ${small ? 'small' : 'medium'}`}>
            {branches.map(branch => (
                <div
                    key={branch.id + (branch.stepName ?? '')}
                    style={onBranchClick ? { cursor: 'pointer' } : {}}
                    onClick={onBranchClick ? (e) => { e.stopPropagation(); onBranchClick(branch); } : undefined}
                >
                    {small ? (
                        <Fragment>
                            <NadcapPie branch={branch} size={24} />
                            <div className="branch-details">
                                <div className="branch-name">{branch.name}</div>
                                <div className="branch-location">{branch.city ? branch.city + ', ' : ''}{branch.country ?? ''}</div>
                            </div>
                            {!!branch.stepColor && (
                                <div
                                    className="branch-step-color"
                                    style={{ backgroundColor: branch.stepColor, boxShadow: `0 0px 12px 6px ${branch.stepColor}30` }}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="branch-header">
                                <NadcapPie branch={branch} size={24} />
                                <div className="branch-name">
                                    <span>{branch.name}</span>
                                    {!!branch.address && <span className="branch-address">{branch.address}</span>}
                                </div>
                                {!!branch.company?.logo && <img src={branch.company.logo} alt={branch.company.name} />}
                            </div>
                            <div className="branch-tags">
                                {!!branch.website && <a href={branch.website} target="_blank" rel="noreferrer"><LinkIcon /><span>{t('branches:website')}</span></a>}
                                {!!branch.stepName && !!branch.stepColor && <div className="branch-step" style={{ backgroundColor: branch.stepColor ?? 'white' }}>{branch.stepName}</div>}
                            </div>
                        </Fragment>
                    )}
                </div>
            ))}
        </div>
    )
}

export default BranchList;