import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Branch } from "../../models/branch";
import { Company } from "../../models/company";
import { Revision } from "../../models/revision";
import { Specification } from "../../models/specification";
import { ObjectDiff } from "../../sg-react/data";
import { BuildingIcon, FactoryIcon, TubeIcon } from "../../sg-react/icons";
import { BubbleHead, Detail, PublicUserCard } from "../../sg-react/ui";
import Collapse from "../../sg-react/ui/Collapse";
import { dateToLocaleString } from "../../sg-react/utils/date";
import { Diff, isType } from "../../sg-react/utils/objects";
import './index.scss';

interface RevisionCardProps {
    revision: Revision;
}

const RevisionCard = ({ revision }: RevisionCardProps) => {
    const { t } = useTranslation();

    const entity = useMemo(() => {
        if (isType<Branch>(revision.entity, (e) => !!e.hasOwnProperty('businessStatus'))) {
            return {
                icon: <FactoryIcon />,
                name: revision.entity.name,
                diff: (
                    <ObjectDiff<Branch>
                        diff={revision.changes as Diff<Branch>}
                        mappers={{ specifications: (e: Specification[] | undefined) => e?.length ? `${e[0].specification} (${e[0].designation})` : '' }}
                    />
                )
            }
        } else if (isType<Specification>(revision.entity, (e) => !!e.hasOwnProperty('specification'))) {
            return {
                icon: <TubeIcon />,
                name: revision.entity.specification,
                diff: (
                    <ObjectDiff<Specification> diff={revision.changes as Diff<Specification>} />
                )
            }
        } else if (isType<Company>(revision.entity, (e) => !!e.hasOwnProperty('name'))) {
            return {
                icon: <BuildingIcon />,
                name: revision.entity.name,
                diff: (
                    <ObjectDiff<Company>
                        diff={revision.changes as Diff<Company>}
                        mappers={{ branches: (e: Branch[] | undefined) => e?.length ? `${e[0].name} (${e[0].address})` : '' }}
                    />
                )
            }
        }
        return null;
    }, [revision]);

    return (
        <div className="revision-card">
            <div className="revision-card-header">
                <div className="revision-card-entity">
                    {entity?.icon}
                    <span>{entity?.name ?? ''}</span>
                    <div className={`revision-card-status revision-card-status-${revision.status.toLowerCase()}`}>
                        {revision.status}
                    </div>
                </div>
                <div className="revision-card-users">
                    {revision.creationUser && (
                        <div>
                            <BubbleHead image={revision.creationUser.avatar} size="small" />
                            <div className="revision-card-user-detail">
                                <span>{t('revisions:createdBy')}<b>{` ${revision.creationUser?.firstName} ${revision.creationUser?.lastName?.[0]?.toUpperCase()}.`}</b></span>
                                <span>{!!revision.createdAt && dateToLocaleString(revision.createdAt, true)}</span>
                            </div>
                            <PublicUserCard user={revision.creationUser} />
                        </div>
                    )}
                    {revision.validationUser && (
                        <div>
                            <BubbleHead image={revision.validationUser.avatar} size="small" />
                            <div className="revision-card-user-detail">
                                <span>{t('revisions:validatedBy')}<b>{` ${revision.validationUser.firstName} ${revision.validationUser.lastName?.[0]?.toUpperCase()}.`}</b></span>
                                <span>{!!revision.validatedAt && dateToLocaleString(revision.validatedAt, true)}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Collapse title={t('revisions:changes')}>
                {entity?.diff}
            </Collapse>
            {!!revision.comment && (
                <Detail
                    label={t('revisions:comment')}
                >
                    <span>{revision.comment}</span>
                </Detail>
            )}
            {!!revision.validationComment && (
                <Detail
                    label={t('revisions:validation_comment')}
                >
                    <span>{revision.validationComment}</span>
                </Detail>
            )}
        </div>
    )
}

export default RevisionCard;